import * as axioCaller from '../axio-caller'
import { convertAndSaveResourcesToStore } from '@sharedComposables/use-store-resources'
import useVNotificationModal from '../use-v-notification-modal'

export const all = ({ state, dispatch, commit }, options) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axioCaller.get({
        url: `${state.baseUrl}`,
        options
      })

      convertAndSaveResourcesToStore(commit, response.data)

      resolve(response)
    } catch (errors) {
      if (errors.response.status === 422) {
        useVNotificationModal({ content: '無法取得資料' })
      } else {
        useVNotificationModal({
          content: ['錯誤!', errors.response.data.message]
            .filter(Boolean)
            .join('')
        })
      }
    }
  })
}

export const find = ({ state, dispatch, commit }, id) => {
  return new Promise(async (resolve, reject) => {
    const response = await axioCaller.get({
      url: `${state.baseUrl}/${id}`
    })

    convertAndSaveResourcesToStore(commit, response.data)

    resolve(response)
  })
}

export const create = (
  { state, dispatch, commit },
  { model, form, options = {} }
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axioCaller.postApiForm({
        url: `${state.baseUrl}`,
        type: 'create-resource',
        form,
        options
      })

      convertAndSaveResourcesToStore(commit, response.data)

      resolve(response)
    } catch (errors) {
      if (
        errors.response.status === 422 &&
        errors.response.data.type === 'form_error'
      ) {
        useVNotificationModal({ content: '請確認表單資料' })
        model.errors.save(errors)
      } else {
        useVNotificationModal({
          content: ['錯誤!', errors.response.data.message]
            .filter(Boolean)
            .join('')
        })
      }
      reject(errors)
    }
  })
}

export const update = (
  { state, dispatch, commit },
  { model, form, options }
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axioCaller.putApiForm({
        url: `${state.baseUrl}/${model.id}`,
        type: 'update-resource',
        form,
        options
      })

      convertAndSaveResourcesToStore(commit, response.data)

      resolve(response)
    } catch (errors) {
      if (
        errors.response.status === 422 &&
        errors.response.data.type === 'form_error'
      ) {
        useVNotificationModal({ content: '請確認表單資料' })
        model.errors.save(errors)
      } else {
        useVNotificationModal({
          content: ['錯誤!', errors.response.data.message]
            .filter(Boolean)
            .join('')
        })
      }
      reject(errors)
    }
  })
}

export const destroy = ({ state, dispatch, commit }, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axioCaller.destroy({
        url: `${state.baseUrl}/${id}`
      })

      convertAndSaveResourcesToStore(commit, response.data)

      resolve(response)
    } catch (errors) {
      useVNotificationModal({
        content: ['錯誤!', errors.response.data.message]
          .filter(Boolean)
          .join('')
      })
    }
  })
}
